export const CST = {
  

// Plans
PLAN_TRIAL: "trial",
PLAN_TRIAL_FREE_CREDITS: 20,

PLAN_PAYASYOUGO: "payasyougo",
CREDIT_UNIT_COST: (5/(1000000)*2)*1000, // ChatGPT4o => $2.5/1M input tokens & $10/1M output tokens (see ratio below) => kept at $0.01 instead of $0.005 due to Stripe restrictions, see credit/token ratio below
MAX_USER_CREDITS_PURCHASE_DOLLAR_AMOUNT: 500, // How much a user can purchase credits in one operation (in $)

PLAN_SUBSCRIPTION: "subscription",
SUBSCRIPTION_MONTHLY: "monthly",
SUBSCRIPTION_YEARLY: "yearly",
SUBSCRIPTION_MONTHLY_COST: 8,
SUBSCRIPTION_YEARLY_COST: 80,
USER_API_KEY_DEFAULT_TPM: 30000,
USER_API_KEY_DEFAULT_RPM: 500,

// Stripe
//STRIPE_PRODUCT_CREDIT_PRICE_ID: "price_1P8Nt9DqqUd8L22JOdnvXf7L", // Test mode
STRIPE_PRODUCT_CREDIT_PRICE_ID: "price_1PMaHaDqqUd8L22JOj1fiY9H",
//STRIPE_PRODUCT_MONTHLY_SUBSCRIPTION_PRICE_ID: "price_1P8NuBDqqUd8L22JOdfGu1Yz", // Test
//STRIPE_PRODUCT_MONTHLY_SUBSCRIPTION_PRICE_ID: "price_1PDRGbDqqUd8L22Ji2H4TxeR", // Daily test
//STRIPE_PRODUCT_YEARLY_SUBSCRIPTION_PRICE_ID: "price_1P9CFSDqqUd8L22JZx271z5y", // Test
STRIPE_PRODUCT_MONTHLY_SUBSCRIPTION_PRICE_ID: "price_1PMaHXDqqUd8L22JpkYBa6KF",
STRIPE_PRODUCT_YEARLY_SUBSCRIPTION_PRICE_ID: "price_1PMaHXDqqUd8L22JrB9SExGq",
STRIPE_OPERATION_TYPE_CREDITS_PURCHASED: "creditsPurchased",
STRIPE_OPERATION_TYPE_SUBSCRIPTION_PURCHASED: "subscriptionPurchased",
STRIPE_OPERATION_TYPE_SUBSCRIPTION_CANCELED: "subscriptionCanceled",
STRIPE_OPERATION_TYPE_SUBSCRIPTION_NOT_CANCELED: "subscriptionNotCanceled",
STRIPE_OPERATION_TYPE_SUBSCRIPTION_INVOICE_PAID: "invoicePaid",


// Firebase Functions
FIREBASE_FUNCTION_MAX_TIMEOUT: 540, // secs (has to be set up in google cloud console)
FIREBASE_9MIN_FUNCTION_TIMEOUT_THRESHOLD:8.5 * 60 * 1000, // After this duration just before the function timeout the function will stop processing data and return the spreadsheet with the values already processed
FIREBASE_FUNCTION_DEFAULT_TIMEOUT: 60, // secs


// Firestore and Storage cleanup durations
CLEAN_UP_DURATION_USER_REVISED_FILES: 30, // days
CLEAN_UP_DURATION_TEMP_DATA: 1, //day

// OpenAI API 
OPEN_AI_API_DEFAULT_TEMPERATURE: 0.7,
OPEN_AI_API_MAX_TEMPERATURE: 2,
OPEN_AI_API_MAX_TOKENS_INPUT: 128000,
OPEN_AI_API_MAX_TOKENS_OUTPUT: 4096,
OPEN_AI_API_AVERAGE_TOKENS_OUTPUT: 200,
OPEN_AI_API_MINIMUM_CREDITS_REQUIRED_TO_RUN_REQUEST: 1,
OPEN_AI_RPM_CHATGPT4_O: 4700, // Max requests per minute chatgpt 4o (5000, rounded down to 4700 with safety factor)
OPEN_AI_TPM_CHATGPT4_O: 430000, // Max tokens processed per minute chatgpt 4o (450k, rounded down at 430k with safety factor)
//OPEN_AI_API_TOKEN_CREDIT_RATIO: 1/1000, // 1 credit = 1000 tokens 
OPEN_AI_API_TOKEN_CREDIT_RATIO_INPUT: 1/2000, // 1 credit input = 2000 tokens (was 1/1000, ie 1 credit input = 1000 tokens, replaced by 1/2000 due to Stripe decimal restrictions) 
OPEN_AI_API_TOKEN_CREDIT_RATIO_OUTPUT: (1/2000) * 4, // 4 credit output = (2000 tokens) openai output tokens cost 4x input tokens ($10 per 1M tokens output vs $2.5 per 1M token input) (was 1/1000, ie 1 credit input = 1000 tokens, replaced by 1/2000 due to Stripe decimal restrictions) 

BACK_OFF_OPTIONS: {
  delayFirstAttempt: false, // Decides whether the startingDelay should be applied before the first call. If false, the first call will occur without a delay. (default: false)
  startingDelay: 1000, // The initial delay in milliseconds (default: 100)
  numOfAttempts: 540, // The maximum number of attempts (default: 10) => set at 9min * 60s = 540s
  delayFactor: 1, // The factor by which the delay is incremented on each attempt (default: 2)
  maxDelay: 1000, // The maximum delay in milliseconds between attempts (default: Infinity)
  jitter: 'full'// Introduces randomness to the delay. Can be 'none', 'full', or 'half' (default: 'none') 'full' adds a random amount between 0 and 100% of the delayFactor
  // 'half' adds a random amount between 0 and 50% of the delayFactor
  // A function that is called before each attempt. Return true to continue or false to stop retrying.
  /*retry: (attemptNumber, error, delay) => {
      this.$print.log(`Attempt ${attemptNumber}: Retrying in ${delay}ms`);
      return true; // return false to stop retrying
  },*/
},
OPEN_AI_MAX_REQUESTS_W_MAX_FIREBASE_TIMEOUT: 3000,
// OPEN_AI_RPM_CHATGPT4_O * FIREBASE_FUNCTION_MAX_TIMEOUT / 60) * 0.8
// Adding safety coeff of 0.8. With 500 RPM and 9min timeout, value = 3600 => rounded down to 3000 (equivalent to number of rows that can be processed in a spreadsheet)
OPEN_AI_MAX_TOKENS_W_MAX_FIREBASE_TIMEOUT: 2000000,
// (OPEN_AI_TPM_CHATGPT4_O * FIREBASE_FUNCTION_MAX_TIMEOUT / 60) * 0.8
// Adding safety coeff of 0.8. With 300k TPM and 9min timeout, value = 2.16M  => rounded to 2M tokens that can be processed in one call to a firebase function
OPEN_AI_MAX_TOKENS_INPUT: 128000,
OPEN_AI_MAX_CHARACTERS_INPUT_ALLOWED_PER_REQUEST: 500000, // approx. 4 characters per token, rounded down to 500k

// chatGPT Batch Process
APP_BATCH_PROCESS_BACKGROUND_RULE: "Return only the result of the task without any introduction or conclusion.",
APP_BATCH_PROCESS_PROMPT_BACKGROUND_CONTEXT: "I will provide you with a text or value. Perform the following task on it: Write a short sentence about . When performing the task, apply the following additional rules: . Return only the result of the task without any introduction or conclusion.. This the text or value to be processed: ",


// Database
  COLLECTION_AIBATCHPROCESS_REVISED_SHEETS: 'AIBatchProcessRevisedSheets',
    COLLECTION_AIBATCHPROCESS_REVISED_SHEETS_USER_ID: 'userId',
    COLLECTION_AIBATCHPROCESS_REVISED_SHEETS_SHEET_NAME: 'sheetName',
    COLLECTION_AIBATCHPROCESS_REVISED_SHEETS_SHEET_CREATED_DATE: 'sheetCreatedDate',
    COLLECTION_AIBATCHPROCESS_REVISED_SHEETS_TEXTS_JSON: 'textsJSON',
    COLLECTION_AIBATCHPROCESS_REVISED_SHEETS_FILE_URL: 'fileUrl',
    COLLECTION_AIBATCHPROCESS_REVISED_SHEETS_FILE_PATH: 'filePath',

  COLLECTION_AIBATCHPROCESS_USER_SETTINGS: 'AIBatchProcessSettings',
    // Doc ID = userId
    COLLECTION_AIBATCHPROCESS_USER_SETTINGS_SAVED_DIRECTIONS: 'savedDirections',
      JSON_AIBATCHPROCESS_USER_SETTINGS_SAVED_DIRECTIONS_NAME: 'name',
      JSON_AIBATCHPROCESS_USER_SETTINGS_SAVED_DIRECTIONS_MAIN: 'main',
      JSON_AIBATCHPROCESS_USER_SETTINGS_SAVED_DIRECTIONS_ADDITIONAL: 'additional',
      JSON_AIBATCHPROCESS_USER_SETTINGS_SAVED_DIRECTIONS_MAX_TOKENS: 'maxTokens',
      JSON_AIBATCHPROCESS_USER_SETTINGS_SAVED_DIRECTIONS_TEMPERATURE: 'temperature',
    COLLECTION_AIBATCHPROCESS_USER_SETTINGS_LAST_DIRECTIONS_USED_NAME: 'lastDirectionsName',

  COLLECTION_TEMP_PROCESS_FILE: 'tempProcessFile',
    COLLECTION_TEMP_PROCESS_FILE_PROGRESS: 'progress',
    COLLECTION_TEMP_PROCESS_FILE_DATE: 'date',
    COLLECTION_TEMP_PROCESS_FILE_CONTINUE: 'stopProcess',
    COLLECTION_TEMP_PROCESS_FILE_DOWNLOAD_LINK: 'downloadLink',
    COLLECTION_TEMP_PROCESS_FILE_REVISED_FILENAME: 'revisedFilename',
    COLLECTION_TEMP_PROCESS_FILE_TOKENS_UTILIZED_INPUT: 'tokensUtilizedInput',
    COLLECTION_TEMP_PROCESS_FILE_TOKENS_UTILIZED_OUTPUT: 'tokensUtilizedOutput',
    COLLECTION_TEMP_PROCESS_FILE_WITH_ERRORS: 'withErrors',

  COLLECTION_OPENAI_API_REQUESTS: 'openAIApiRequests',
    COLLECTION_OPENAI_API_REQUESTS_STATUS: 'status',
    COLLECTION_OPENAI_API_REQUESTS_DATE: 'date',
    COLLECTION_OPENAI_API_REQUESTS_USER: 'user',
    COLLECTION_OPENAI_API_REQUESTS_USER_PLAN: 'userPlan',
    COLLECTION_OPENAI_API_REQUESTS_PROMPT: 'prompt',
    COLLECTION_OPENAI_API_REQUESTS_RESULT: 'result',
    COLLECTION_OPENAI_API_REQUESTS_TOKENS_TOTAL: 'tokensTotal',
    COLLECTION_OPENAI_API_REQUESTS_TOKENS_INPUT: 'tokensInput',
    COLLECTION_OPENAI_API_REQUESTS_TOKENS_OUTPUT: 'tokensOutput',

  COLLECTION_USER_PROFILE: 'userProfile',
    // Doc ID = userId
    COLLECTION_USER_PROFILE_AIFFICIENTOOLS_KEY: 'AIfficientoolsKey',
    COLLECTION_USER_PROFILE_AIFFICIENTOOLS_KEY_LAST_FOUR_DIGITS: 'AIfficientoolsKeyLastFourDigits',   
    COLLECTION_USER_PROFILE_CURRENT_PLAN: 'currentPlan',
    COLLECTION_USER_PROFILE_CREDITS: 'credits',
    COLLECTION_USER_PROFILE_IS_SUBSCRIPTION_ACTIVE: 'subscriptionActive',
    COLLECTION_USER_PROFILE_SUBSCRIPTION_STRIPE_ID: 'subscriptionStripeID',
    COLLECTION_USER_PROFILE_SUBSCRIPTION_RECURRENCE: 'subscriptionRecurrence',
    COLLECTION_USER_PROFILE_SUBSCRIPTION_START_CURRENT_CYCLE: 'subscriptionStartCurrentCycle',
    COLLECTION_USER_PROFILE_SUBSCRIPTION_END_CURRENT_CYCLE: 'subscriptionEndCurrentCycle',
    COLLECTION_USER_PROFILE_SUBSCRIPTION_CANCELED_AT: 'subscriptionCanceledAt',
    COLLECTION_USER_PROFILE_SUBSCRIPTION_OPEN_AI_API_KEY: 'userApiKey',
    COLLECTION_USER_PROFILE_SUBSCRIPTION_OPEN_AI_API_KEY_LAST_FOUR_DIGITS: 'userApiKeyLastFourDigits',
    COLLECTION_USER_PROFILE_SUBSCRIPTION_OPEN_AI_API_KEY_TPM: 'userApiKeyTPM',
    COLLECTION_USER_PROFILE_SUBSCRIPTION_OPEN_AI_API_KEY_RPM: 'userApiKeyRPM',

    COLLECTION_USER_PROFILE_STRIPE_CUSTOMER_ID: 'stripeId',
    //COLLECTION_USER_PROFILE_STRIPE_CUSTOMER_DASHBOARD_LINK: 'stripeLink',
    COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS: 'subscriptionCreditOperations',
      COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS_DATE: 'date',
      COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS_EVENT_TYPE: 'eventType',
      COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS_CHECKOUT_SESSION_ID: 'checkoutSessionID',
      COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS_STRIPE_EVENT: 'stripeEvent',
      COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS_CREDITS_ADDED: 'PurchaseCredits',
      COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS_SUBSCRIPTION_RECURRENCE: 'subscriptionRecurrence',
      COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS_SUBSCRIPTION_START: 'subscriptionStart',
      COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS_SUBSCRIPTION_END: 'subscriptionEnd',
      COLLECTION_USER_PROFILE_SUBCOLLECTION_SUBSCRIPTION_CREDIT_OPERATIONS_SUBSCRIPTION_CANCELED_AT:'canceledAt',  
      
    COLLECTION_OFFICE_ADDIN_SIGNIN_TEMP: 'officeAddinSigninTemp',
      COLLECTION_OFFICE_ADDIN_SIGNIN_TEMP_ID: 'id',
      COLLECTION_OFFICE_ADDIN_SIGNIN_TEMP_CUSTOM_TOKEN: 'customToken',
      COLLECTION_OFFICE_ADDIN_SIGNIN_TEMP_STATUS: 'status',

    OFFICE_ADDIN_SIGNIN_TEMP_STATUS_SUCCESS: "success",
    OFFICE_ADDIN_SIGNIN_TEMP_STATUS_NOT_ALREADY_SIGNED_IN: "not already signed in",
 
};
