import { initializeApp, getApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getStripePayments } from "@invertase/firestore-stripe-payments";



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDMKVV6aP6NUJGmzADckm27i60M_4X1dHA",
    authDomain: "aifficientools.firebaseapp.com",
    projectId: "aifficientools",
    storageBucket: "aifficientools.appspot.com",
    messagingSenderId: "107079895777",
    appId: "1:107079895777:web:38e265c359b810da7dc5a0",
    measurementId: "G-2XL2T347M6"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Add firebase analytics
export const firebaseAnalytics = getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
// here we can export reusable database references
export const todosRef = collection(db, 'todos')

export const firebaseFunctions = getFunctions(firebaseApp, 'us-central1');

// Only functions emulators in development mode
if (import.meta.env.MODE === 'development') {
    connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);
}

// Initialize Firebase Authentication and get a reference to the service
export const firebaseAuth = getAuth(firebaseApp);

export const firebaseStorage = getStorage()

// Initialize Stripe
const app = getApp();
export const stripePayments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});


